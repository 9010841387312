import { DateTime, Utc } from "./datetime";
import { dev } from "./log";
import { NumRange, _AbstractRange } from "./range";

export namespace NaiveDateSerialized {
  // month and day are 1-indexed
  export function fromYmd(year: number, month: number, dayOfMonth: number) {
    return `${year}-${String(month).padStart(2, "0")}-${String(
      dayOfMonth
    ).padStart(2, "0")}`;
  }
}

// 2023-01-15
export class NaiveDate {
  utc: DateTime<Utc>;

  get year() {
    return this.utc.year;
  }

  get month() {
    return this.utc.month + 1;
  }

  get day() {
    return this.utc.dayOfMonth;
  }

  // callers must guarantee that hours-mins-secs are zeroed!
  private constructor(utc: DateTime<Utc>) {
    if (isNaN(utc.getTime())) {
      console.trace();
    }
    this.utc = utc;
  }

  static today(): NaiveDate {
    const date = new Date();
    return NaiveDate.fromYmd(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
  }

  static fromUtcMidnight(utcMidnight: DateTime<Utc>) {
    return new NaiveDate(utcMidnight);
  }

  // month and day are 1-indexed
  static fromYmd(year: number, month: number, dayOfMonth: number): NaiveDate {
    const dt = DateTime.fromDate(
      new Date(year, month - 1, dayOfMonth)
    ).toMidnightUTC();
    return NaiveDate.fromUtcMidnight(dt);
  }

  static fromSerialized(s: NaiveDateSerialized) {
    const dt = DateTime.fromDate(new Date(s)).toMidnightUTC();
    return NaiveDate.fromUtcMidnight(dt);
  }

  differenceInDays(o: NaiveDate): number {
    return this.utc.differenceInDays(o.utc);
  }

  serialize(): NaiveDateSerialized {
    return NaiveDateSerialized.fromYmd(this.year, this.month, this.day);
  }

  toString(): NaiveDateSerialized {
    return this.serialize();
  }

  add({
    days,
    months,
    years,
  }: {
    days?: number;
    months?: number;
    years?: number;
  }): NaiveDate {
    if (!days && !months && !years) return this;

    let d: NaiveDate = this;
    if (days != null) {
      d = NaiveDate.fromUtcMidnight(this.utc.add({ days }));
    }
    if (months != null || years != null) {
      const totalMonths =
        d.year * 12 + d.month + (years ?? 0) * 12 + (months ?? 0);
      d = NaiveDate.fromYmd(
        Math.floor(totalMonths / 12),
        totalMonths % 12,
        d.day
      );
    }
    return d;
  }

  toMonday(): NaiveDate {
    return this.add({ days: -this.utc.getDayOfWeek() + 1 });
  }

  equals(o: NaiveDate): boolean {
    return this.utc.getTime() == o.utc.getTime();
  }

  isOnSameDate(dt: DateTime<any>): boolean {
    return (
      this.year == dt.year &&
      this.day == dt.dayOfMonth &&
      this.month == dt.month + 1
    );
  }

  isBefore(o: NaiveDate): boolean {
    return this.utc.getTime() < o.utc.getTime();
  }
}

export type NaiveDateRange = _AbstractRange<NaiveDate>;

export namespace NaiveDateRange {
  export function containsExclusiveEnd(
    r: NaiveDateRange,
    d: NaiveDate
  ): boolean {
    return NumRange.containsExclusiveEnd(
      { start: r.start.utc.getTime(), end: r.end.utc.getTime() },
      d.utc.getTime()
    );
  }

  export function contains(r: NaiveDateRange, d: NaiveDate) {
    return NumRange.contains(
      { start: r.start.utc.getTime(), end: r.end.utc.getTime() },
      d.utc.getTime()
    );
  }
}

import { Duration } from "./chrono/duration";
import { NaiveDate, NaiveDateSerialized } from "./naive-date";
import { _AbstractRange } from "./range";
import { Utils } from "./utils";

export type Utc = {
  utc: "UTC";
};
export type FixedOffset = {
  fixed: "FIXED";
};
export type Local = {
  local: "LOCAL";
};
export type AnyTimezone = Utc | FixedOffset | Local;

export namespace TimeConstants {
  export const HOURS_PER_DAY = 24;
  export const DAYS_OF_WEEK = 7;
  export const MILLISECONDS_PER_DAY = 86_400_000;
  export const MILLISECONDS_PER_WEEK = MILLISECONDS_PER_DAY * DAYS_OF_WEEK;
  export const MILLISECONDS_PER_HOUR = 3_600_000;
  export const MILLISECONDS_PER_MINUTE = 60_000;

  export const MONTHS_OF_YEAR = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  export const MONTHS_OF_YEAR_SHORT = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  export const DAYS_OF_WEEK_NAME = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ];
  export const DAYS_OF_WEEK_FULL_NAME = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
}

function midnight(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export type DateInfo = {
  sec: number;
  min: number;
  hour: number;
  dayOfMonth: number;
  month: number;
  year: number;
  dayOfWeek: number;
  dayOfYear: number;
};

export class DateTime<T> {
  private ms: number = 0;

  private _tzOffset: number = 0;
  get tzOffset(): number {
    return this._tzOffset;
  }

  private dateInfo: DateInfo;

  // @ts-ignore
  // TODO: add this so the compiler complains if we use incompatible datetimes
  private t: T;

  constructor(msSinceEpoch: number, tzOffsetMins: number) {
    this.ms = msSinceEpoch;
    this._tzOffset = tzOffsetMins;
    this.dateInfo = DateTime.createDateInfo(
      Math.floor((this.ms + this._tzOffset * 60_000) / 1000)
    );
  }

  static UTC(ms: number): DateTime<Utc> {
    return new DateTime(ms, 0);
  }

  static localTzOffset(): number {
    const date = new Date();
    return -date.getTimezoneOffset();
  }

  static midnightLocalTz(): DateTime<Local> {
    const date = midnight(new Date());
    return new DateTime(date.getTime(), -date.getTimezoneOffset());
  }

  static midnightUTC(): DateTime<Utc> {
    return this.nowUTC().toMidnight();
  }

  static nowWithTz(tzOffset: number): DateTime<FixedOffset> {
    return new DateTime(new Date().getTime(), tzOffset);
  }

  static nowLocalTz(): DateTime<FixedOffset> {
    return DateTime.fromDate(new Date());
  }

  static nowUTC(): DateTime<Utc> {
    return DateTime.UTC(new Date().getTime());
  }

  static fromDate(date: Date): DateTime<FixedOffset> {
    return new DateTime(date.getTime(), -date.getTimezoneOffset());
  }

  static fromRFC3339(value: string): DateTime<Utc> {
    // NOTE: getTime() is always time since epoch in UTC
    const date = new Date(value);
    // dev(value, date.getTimezoneOffset());
    // TODO: we need to parse the tzoffset manually
    return new DateTime(date.getTime(), 0);
  }

  static fromMillisecondsUTC(millisecondsUTC: number): DateTime<Utc> {
    return new DateTime(millisecondsUTC, 0);
  }

  static DAYS_SINCE_JAN_1: number[][] = [
    [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365],
    [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335, 366],
  ];

  /*
   https://stackoverflow.com/questions/11188621/how-can-i-convert-seconds-since-the-epoch-to-hours-minutes-seconds-in-java
  */
  // static

  static createDateInfo: (secondsSinceEpoch: number) => DateInfo =
    Utils.memoize(DateTime._createDateInfo);
  private static _createDateInfo(secondsSinceEpoch: number): DateInfo {
    let sec: number;
    let quadricentennials: number,
      centennials: number,
      quadrennials: number,
      annuals: number /*1-ennial?*/;
    let year: number, leap;
    let yday: number, hour: number, min: number;
    let month: number, mday: number, wday: number;

    // Re-bias from 1970 to 1601:
    // 1970 - 1601 = 369 = 3*100 + 17*4 + 1 years (incl. 89 leap days) =
    // (3*100*(365+24/100) + 17*4*(365+1/4) + 1*365)*24*3600 seconds
    sec = secondsSinceEpoch + 11644473600;

    wday = Math.floor((sec / 86400 + 1) % 7); // day of week

    // Remove multiples of 400 years (incl. 97 leap days)
    quadricentennials = Math.floor(sec / 12622780800); // 400*365.2425*24*3600
    sec %= 12622780800;

    // Remove multiples of 100 years (incl. 24 leap days), can't be more than 3
    // (because multiples of 4*100=400 years (incl. leap days) have been removed)
    centennials = Math.floor(sec / 3155673600); // 100*(365+24/100)*24*3600
    if (centennials > 3) {
      centennials = 3;
    }
    sec -= centennials * 3155673600;

    // Remove multiples of 4 years (incl. 1 leap day), can't be more than 24
    // (because multiples of 25*4=100 years (incl. leap days) have been removed)
    quadrennials = Math.floor(sec / 126230400); // 4*(365+1/4)*24*3600
    if (quadrennials > 24) {
      quadrennials = 24;
    }
    sec -= quadrennials * 126230400;

    // Remove multiples of years (incl. 0 leap days), can't be more than 3
    // (because multiples of 4 years (incl. leap days) have been removed)
    annuals = Math.floor(sec / 31536000); // 365*24*3600
    if (annuals > 3) {
      annuals = 3;
    }
    sec -= annuals * 31536000;

    // Calculate the year and find out if it's leap
    year =
      1601 +
      quadricentennials * 400 +
      centennials * 100 +
      quadrennials * 4 +
      annuals;
    leap = !(year % 4) && (year % 100 || !(year % 400));

    // Calculate the day of the year and the time
    yday = Math.floor(sec / 86400);
    sec %= 86400;
    hour = Math.floor(sec / 3600);
    sec %= 3600;
    min = Math.floor(sec / 60);
    sec %= 60;

    // Calculate the month
    for (mday = month = 1; month < 13; month++) {
      if (yday < DateTime.DAYS_SINCE_JAN_1[leap ? 1 : 0][month]) {
        mday += yday - DateTime.DAYS_SINCE_JAN_1[leap ? 1 : 0][month - 1];
        break;
      }
    }

    return {
      sec,
      min,
      hour,
      dayOfMonth: mday,
      month: month - 1,
      year,
      dayOfWeek: wday,
      dayOfYear: yday,
    };
  }

  getTime(): number {
    return this.ms + this._tzOffset * 60_000;
  }

  getTimeUTC(): number {
    return this.ms;
  }

  getMilliseconds(): number {
    return this.ms % 1000;
  }

  get hour(): number {
    return this.dateInfo.hour;
  }

  getHours(): number {
    return this.dateInfo.hour;
  }

  // (0..24]
  get hoursFractional(): number {
    return this.dateInfo.hour + this.dateInfo.min / 60;
  }

  // [0..24)
  get hoursFractionalInclusive24(): number {
    const hr = this.dateInfo.hour + this.dateInfo.min / 60;
    return hr == 0 ? 24 : 0;
  }

  // 12:00am
  getTimeOfDayAmPmReadable(): string {
    return (
      `${
        this.dateInfo.hour == 0 || this.dateInfo.hour == 12
          ? 12
          : this.dateInfo.hour % 12
      }:${String(this.dateInfo.min).padStart(2, "0")}` +
      (this.dateInfo.hour >= 12 ? "pm" : "am")
    );
  }

  getRelativeDateReadableShort(today: DateTime<T>): string {
    const midnight = this.toMidnight();
    const deltaDays = midnight.differenceInDays(today);
    switch (deltaDays) {
      case 1:
        return "Tomorrow";
      case 0:
        return "Today";
      case -1:
        return "Yesterday";
    }
    if (
      deltaDays < 7 &&
      deltaDays > 0 &&
      this.getDayOfWeek7() > today.getDayOfWeek7()
    ) {
      return this.getDayOfWeekName();
    }
    return `${this.monthStringShort} ${this.getDayOfMonth()}`;
  }

  getRelativeDateReadable(
    includeYear: boolean = false,
    today: DateTime<T>
  ): string {
    const midnight = this.toMidnight();
    const deltaDays = midnight.differenceInDays(today);
    switch (deltaDays) {
      case 1:
        return "Tomorrow";
      case 0:
        return "Today";
      case -1:
        return "Yesterday";
    }
    if (
      deltaDays < 7 &&
      deltaDays > 0 &&
      this.getDayOfWeek7() > today.getDayOfWeek7()
    ) {
      return this.getDayOfWeekFullName();
    }
    return (
      `${this.getMonthString()} ${this.getDayOfMonth()}` +
      (includeYear ? `, ${this.getYear()}` : "")
    );
  }

  getDateTimeReadableProper(): string {
    return `${this.getMonthString()} ${this.getDayOfMonth()}, ${this.getTimeReadable()}`;
  }

  getDateTimeReadable(): string {
    return `${this.getTimeReadable()} - ${this.getMonthString()} ${this.getDayOfMonth()}`;
  }

  get minute(): number {
    return this.dateInfo.min;
  }
  getMinutes(): number {
    return this.dateInfo.min;
  }

  get second(): number {
    return this.dateInfo.sec;
  }
  getSeconds(): number {
    return this.dateInfo.sec;
  }

  // 0-indexed
  get month(): number {
    return this.dateInfo.month;
  }
  getMonth(): number {
    return this.dateInfo.month;
  }

  getMonthString(): string {
    return TimeConstants.MONTHS_OF_YEAR[this.dateInfo.month];
  }

  get monthStringShort(): string {
    return TimeConstants.MONTHS_OF_YEAR_SHORT[this.dateInfo.month];
  }

  get year(): number {
    return this.dateInfo.year;
  }
  getYear(): number {
    return this.dateInfo.year;
  }

  getDayOfWeek(): number {
    return this.dateInfo.dayOfWeek;
  }

  getDayOfWeek7(): number {
    return this.dateInfo.dayOfWeek == 0 ? 7 : this.dateInfo.dayOfWeek;
  }

  // 1-indexed
  get dayOfMonth(): number {
    return this.dateInfo.dayOfMonth;
  }
  getDayOfMonth(): number {
    return this.dateInfo.dayOfMonth;
  }

  getDayOfWeekName(): string {
    return TimeConstants.DAYS_OF_WEEK_NAME[this.dateInfo.dayOfWeek];
  }

  getDayOfWeekFullName(): string {
    return TimeConstants.DAYS_OF_WEEK_FULL_NAME[this.dateInfo.dayOfWeek];
  }

  getDayOfYear(): number {
    return this.dateInfo.dayOfYear;
  }

  getWeekNumber(): number {
    return Math.ceil((this.getDayOfYear() + 1) / 7);
  }

  toString(): string {
    return `${this.dateInfo.month + 1}/${this.dateInfo.dayOfMonth}/${
      this.dateInfo.year
    } ${this.dateInfo.hour}:${pad(String(this.dateInfo.min), 2, "0")}, ${
      TimeConstants.DAYS_OF_WEEK_NAME[this.dateInfo.dayOfWeek]
    }${this._tzOffset > 0 ? "+" : "-"}${Math.abs(this._tzOffset) / 60}`;
  }

  isOnSameDate(other: DateTime<T>): boolean {
    const info = this.dateInfo;
    const otherInfo = other.dateInfo;
    return (
      info.year == otherInfo.year &&
      info.month == otherInfo.month &&
      info.dayOfYear == otherInfo.dayOfYear
    );
  }

  isBefore(other: DateTime<T>): boolean {
    return this.getTimeUTC() < other.getTimeUTC();
  }

  isBeforeOrEqual(other: DateTime<T>): boolean {
    return this.getTimeUTC() <= other.getTimeUTC();
  }

  isBeforeDay(other: DateTime<T>): boolean {
    if (other.getYear() > this.getYear()) {
      return true;
    }
    if (other.getYear() < this.getYear()) {
      return false;
    }
    if (other.getMonth() > this.getMonth()) {
      return true;
    }
    if (other.getMonth() < this.getMonth()) {
      return false;
    }
    return this.getDayOfMonth() < other.getDayOfMonth();
  }

  isSameAmPm(other: DateTime<T>): boolean {
    return (
      (this.getHours() < 12 && other.getHours() < 12) ||
      (this.getHours() >= 12 && other.getHours() >= 12)
    );
  }

  add({
    days,
    hours,
    mins,
    secs,
    ms,
  }: {
    days?: number;
    hours?: number;
    mins?: number;
    secs?: number;
    ms?: number;
  }): DateTime<T> {
    const newMs =
      this.ms +
      (days ?? 0) * 60_000 * 60 * 24 +
      (hours ?? 0) * 60_000 * 60 +
      (mins ?? 0) * 60_000 +
      (secs ?? 0) * 1000 +
      (ms ?? 0);
    return new DateTime(newMs, this._tzOffset);
  }

  min(other: DateTime<T>): DateTime<T> {
    return this.ms < other.ms ? this : other;
  }

  max(other: DateTime<T>): DateTime<T> {
    return this.ms > other.ms ? this : other;
  }

  toMonday(): DateTime<T> {
    const dow = this.getDayOfWeek();
    if (dow == 0) {
      return this.add({ days: -6 });
    }
    return this.add({
      days: -dow + 1,
    });
  }

  toHourOfDay(hours: number): DateTime<T> {
    return this.add({
      hours: -this.getHours() + hours,
      mins: -this.getMinutes(),
      secs: -this.getSeconds(),
      ms: -this.getMilliseconds(),
    });
  }

  toMidnightUTC(): DateTime<Utc> {
    const utcTime = DateTime.UTC(this.getTimeUTC());
    return utcTime.add({
      hours: -utcTime.getHours(),
      mins: -utcTime.getMinutes(),
      secs: -utcTime.getSeconds(),
      ms: -utcTime.getMilliseconds(),
    });
  }

  toMidnight(): DateTime<T> {
    return this.add({
      hours: -this.getHours(),
      mins: -this.getMinutes(),
      secs: -this.getSeconds(),
      ms: -this.getMilliseconds(),
    });
  }

  toCeilHour(): DateTime<T> {
    return this.add({
      mins: 59 - this.getMinutes(),
      secs: 59 - this.getSeconds(),
      ms: 1000 - this.getMilliseconds(),
    });
  }

  toFloor15(): DateTime<T> {
    const nearest15 = Math.trunc(this.getMinutes() / 15) * 15;
    return this.add({
      mins: nearest15 - this.getMinutes(),
      secs: -this.getSeconds(),
      ms: -this.getMilliseconds(),
    });
  }

  toNearest15(): DateTime<T> {
    const nearest15 = Math.round(this.getMinutes() / 15) * 15;
    return this.add({
      mins: nearest15 - this.getMinutes(),
      secs: -this.getSeconds(),
      ms: -this.getMilliseconds(),
    });
  }

  toBeginningOfMonth(): DateTime<T> {
    return this.add({ days: -this.getDayOfMonth() + 1 });
  }

  toBeginningOfWeek(): DateTime<T> {
    return this.add({ days: -this.getDayOfWeek() + 1 });
  }

  toUtc(convertTime: boolean = true): DateTime<Utc> {
    if (convertTime) {
      return this.toTimezoneOffsetConvertingTimeOfDay(
        0
      ) as unknown as DateTime<Utc>;
    }
    return this.toTimezoneOffsetPreservingTimeOfDay(
      0
    ) as unknown as DateTime<Utc>;
  }

  toFixedOffset(
    offset: number,
    convertTime: boolean = true
  ): DateTime<FixedOffset> {
    if (convertTime) {
      return this.toTimezoneOffsetConvertingTimeOfDay(
        offset
      ) as unknown as DateTime<FixedOffset>;
    }
    return this.toTimezoneOffsetPreservingTimeOfDay(
      offset
    ) as unknown as DateTime<FixedOffset>;
  }

  toTimezoneOffsetConvertingTimeOfDay(
    timezoneOffsetMins: number
  ): DateTime<FixedOffset> {
    return new DateTime(this.ms, timezoneOffsetMins);
  }

  toTimezoneOffsetPreservingTimeOfDay(
    timezoneOffsetMins: number
  ): DateTime<FixedOffset> {
    return new DateTime(
      this.ms - timezoneOffsetMins * 60_000,
      timezoneOffsetMins
    );
  }

  differenceInDays(other: DateTime<T>, ceil: boolean = false): number {
    return (ceil ? Math.ceil : Math.floor)(
      (this.ms - other.ms) / (60_000 * 60 * 24)
    );
  }

  differenceInHrs(other: DateTime<T>): number {
    return (this.ms - other.ms) / (60_000 * 60);
  }

  differenceInMs(other: DateTime<T>): number {
    return Math.floor(this.ms - other.ms);
  }

  difference(other: DateTime<T>): Duration {
    return Duration.from({
      ms: this.ms - other.ms,
    });
  }

  differenceReadable(other: DateTime<T>): string {
    return Duration.msToString(Math.floor(this.ms - other.ms));
  }

  private getTzRfc3339(): string {
    if (this._tzOffset == 0) return "Z";
    const abs = Math.abs(this._tzOffset);
    return `${this._tzOffset > 0 ? "+" : "-"}${String(
      Math.floor(abs / 60)
    ).padStart(2, "0")}:${String(abs % 60).padStart(2, "0")}`;
  }

  rfc3339(): RFC3339 {
    // 2022-06-12T17:00:00.000Z
    // 2022-05-05T00:00.000Z
    const hourStr = String(this.getHours()).padStart(2, "0");
    const minStr = String(this.getMinutes()).padStart(2, "0");
    const dateStr = String(this.getDayOfMonth()).padStart(2, "0");
    return `${this.getYear()}-${String(this.getMonth() + 1).padStart(
      2,
      "0"
    )}-${dateStr}T${hourStr}:${minStr}:00.00${this.getTzRfc3339()}`;
  }

  // https://en.wikipedia.org/wiki/ISO_8601#Dates
  // %Y-%M-%D
  iso8601_date(): string {
    return `${this.getYear()}-${String(this.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(this.getDayOfMonth()).padStart(2, "0")}`;
  }

  static createReadableHourStringWithHr(
    timeHrs: number,
    options?: {
      includeAmPm?: boolean;
      allCaps?: boolean;
      includeMinutes?: boolean;
      use24Hours?: boolean;
    }
  ): string {
    return DateTime.createReadableHourString(
      Math.floor(timeHrs),
      Math.floor((timeHrs % 1) * 60),
      options
    );
  }

  static createReadableHourString(
    hour: number,
    min: number,
    options?: {
      includeAmPm?: boolean;
      allCaps?: boolean;
      includeMinutes?: boolean;
      use24Hours?: boolean;
    }
  ): string {
    const { allCaps, includeAmPm, includeMinutes, use24Hours } = {
      allCaps: options?.allCaps ?? true,
      includeAmPm: options?.includeAmPm ?? true,
      includeMinutes: options?.includeMinutes ?? true,
      use24Hours: options?.use24Hours ?? false,
    };
    const hoursMod = use24Hours ? 24 : 12;

    let amPm = hour >= 12 ? "pm" : "am";
    if (allCaps) {
      amPm = amPm.toUpperCase();
    }
    let displayHour = hour % hoursMod;
    displayHour = displayHour == 0 ? hoursMod : displayHour;
    min = Math.round(min);
    if (min == 0 && !includeMinutes) {
      return includeAmPm ? `${displayHour} ${amPm}` : String(displayHour);
    } else {
      const timeStr = `${displayHour}:${min.toString().padStart(2, "0")}`;
      return includeAmPm ? `${timeStr} ${amPm}` : timeStr;
    }
  }

  getTimeReadable(options?: {
    includeAmPm?: boolean;
    allCaps?: boolean;
    includeMinutes?: boolean;
  }) {
    return DateTime.createReadableHourString(
      this.getHours(),
      this.getMinutes(),
      options
    );
  }

  asFixedOffset(): DateTime<FixedOffset> {
    return this as unknown as DateTime<FixedOffset>;
  }

  static fromNaiveDate(s: NaiveDate): DateTime<Utc> {
    return s.utc;
  }

  static fromNaiveDateSerialized(s: NaiveDateSerialized): DateTime<Utc> {
    return NaiveDate.fromSerialized(s).utc;
  }

  toNaiveDate(): NaiveDate {
    return NaiveDate.fromYmd(this.year, this.month + 1, this.dayOfMonth);
  }
}

/*
 DateTimeRange
 ===============================================================================
*/
export type DateTimeRange<Tz> = _AbstractRange<DateTime<Tz>>;
export namespace DateTimeRange {
  export function overlapsWithDay<T>(
    range: DateTimeRange<T>,
    date: DateTime<T>
  ): "start" | "middle" | "end" | "none" {
    if (range.start.isOnSameDate(date)) return "start";
    if (range.end.isOnSameDate(date)) return "end";
    if (DateTimeRange.contains(range, date)) return "middle";
    return "none";
  }

  export function contains<T>(
    range: DateTimeRange<T>,
    date: DateTime<T>
  ): boolean {
    return (
      range.start.getTimeUTC() < date.getTimeUTC() &&
      range.end.getTimeUTC() > date.getTimeUTC()
    );
  }

  export function toUtc<T>(range: DateTimeRange<T>): DateTimeRange<Utc> {
    return {
      start: range.start.toUtc(),
      end: range.end.toUtc(),
    };
  }
}

import { Cookies } from "./cookies";

export namespace DevTools {
  const useDevIdentifier = "use-dev-js";

  export function DevJsEnabled(): boolean {
    return Cookies.has(useDevIdentifier);
  }

  export function toggleDevJs(force: boolean, domain: Option<string>) {
    Cookies.toggle(useDevIdentifier, {
      force,
      domain,
    });
  }
}

window["admin"] = DevTools;

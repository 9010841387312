export namespace MathUtils {
  export const lerp = (min: number, max: number, progress: number): number => {
    return min + (max - min) * progress;
  };

  export const easeInSine = (x: number): number => {
    return 1 - Math.cos((x * Math.PI) / 2);
  };

  export const easeInElastic = (x: number): number => {
    const c4 = (2 * Math.PI) / 3;
    return x === 0
      ? 0
      : x === 1
      ? 1
      : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4);
  };

  export const easeOutCubic = (x: number): number => {
    return clamp(1 - Math.pow(1 - x, 3), 0, 1);
  };

  export function clamp(num: number, min: number, max: number): number {
    return Math.min(max, Math.max(min, num));
  }

  export function numberOrNull(n: Option<number>): Option<number> {
    if (n === null || n === undefined) return null;
    if (isNaN(n)) return null;
    return n;
  }

  export function mod(n: number, mod: number): number {
    if (n > 0) return n % mod;
    return (mod - (-n % mod)) % mod;
  }
}

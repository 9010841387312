import { nonnull } from "../assert";
import { MathUtils } from "../math";

export class Duration {
  ms: number;

  constructor(ms: number) {
    this.ms = ms;
  }

  static from(time: {
    hours?: Option<number>;
    mins?: Option<number>;
    ms?: Option<number>;
  }): Duration {
    return new Duration(
      (time.hours ?? 0) * Duration.MILLISECOND_PER_HOUR +
        (time.mins ?? 0) * Duration.MILLISECOND_PER_MIN +
        (time.ms ?? 0)
    );
  }

  static fromHrsOfDayFormat(format: string): Duration {
    const [startHr, startMins] = format.split(":");
    return Duration.from({
      hours: nonnull(MathUtils.numberOrNull(parseInt(startHr))),
      mins: nonnull(MathUtils.numberOrNull(parseInt(startMins))),
    });
  }

  toHrsOfDayFormat(): string {
    const hours = this.hourOfDay();
    const mins = this.minOfHour();
    if (mins < 10) {
      return `${hours}:0${mins}`;
    }
    return `${hours}:${mins}`;
  }

  hourOfDayFrac() {
    return (
      (this.ms % Duration.MILLISECOND_PER_DAY) / Duration.MILLISECOND_PER_HOUR
    );
  }

  hourOfDay() {
    return Math.floor(
      (this.ms % Duration.MILLISECOND_PER_DAY) / Duration.MILLISECOND_PER_HOUR
    );
  }

  minOfHour() {
    return Math.floor(
      (this.ms % Duration.MILLISECOND_PER_HOUR) / Duration.MILLISECOND_PER_MIN
    );
  }

  add(duration: Duration): Duration {
    return new Duration(duration.ms + this.ms);
  }

  sub(duration: Duration): Duration {
    return new Duration(duration.ms - this.ms);
  }
}

export namespace Duration {
  export const MILLISECOND_PER_SEC = 1_000;
  export const MILLISECOND_PER_MIN = 60_000;
  export const MILLISECOND_PER_HOUR = 3_600_000;
  export const MILLISECOND_PER_DAY = 86_400_000;

  export const ZERO = new Duration(0);

  export function msToString(durationMs: number): string {
    const pluralize = (s: string, n: number) => {
      if (n > 1) return s + "s";
      return s;
    };
    const ms = durationMs;
    const days = Math.floor(ms / (60_000 * 60 * 24));
    const hours = Math.floor((ms / (60_000 * 60)) % 24);
    const mins = Math.floor((ms / 60_000) % 60);
    if (days > 0)
      return `${days} ${pluralize("day", days)}, ${hours} ${pluralize(
        "hour",
        hours
      )}`;
    if (hours > 0)
      return `${hours} ${pluralize("hour", hours)}, ${mins} ${pluralize(
        "min",
        mins
      )}`;
    if (mins > 0) return `${mins} ${pluralize("min", mins)}`;
    const secs = Math.floor((ms / 1000) % 60);
    return `${secs} ${pluralize("sec", secs)}`;
  }
}
